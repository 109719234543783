
//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

//Style
import './LogsReport.scss';
import FilterLogReport from './components/FilterLogReport';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import TableLogsReport from './components/TableLogReport';

function LogsReport() {

    const [info, setInfo] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const [filterBooking, setFilterBooking] = useState<string>("")
    const [filterDate, setFilterDate] = useState<string>("");
    const [filterIntegrationType, setFilterIntegrationType] = useState<any>("");
    const [filterIntegrationStatus, setFilterIntegrationStatus] = useState<any>("");
    
    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);

    async function buscar(
        booking: string,
        date: string,
        type: string,
        status: string,
        page: number = 1
    ) {
        setInfo(null);
        setLoading(true);
        setFilterBooking(booking);
        setFilterDate(date);
        setFilterIntegrationType(type);
        setFilterIntegrationStatus(status);
        setPageCount(page);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post('/Log/GetIntegrationLogByFilterAsync',
                {
                    "page": page,
                    "rowsPerPage": rowsPerPage,
                    "bookingLoc": booking,
                    "date": date[0] ? date[0].split("/").reverse().join("-") : "",
                    "type": type === "0" ? "" : type,
                    "status": status === "0" ? "" : status
                }, config);

            if (res.status === 200) {
                setLoading(false);
                setInfo(res.data.data.data.rows);
                setTotalRows(res.data.data.data.rowsCount);
            } else {
                setInfo(null);
            }
        } catch (error: any) {
            setLoading(false);
            setInfo(null);
        }
    }

    useEffect(() => {
        buscar(filterBooking, filterDate, filterIntegrationType, filterIntegrationStatus, pageCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard logs-report">
                    <Breadcrumb title={'Reporte de Logs'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Reporte de Logs</h2>
                    </div>

                    <FilterLogReport buscar={buscar} />

                    <TableLogsReport
                        info={info}
                        loading={loading}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />

                </div>
            </DesktopDefault>
        </>

    );
}

export default LogsReport;