import React, { useState} from 'react';


import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from '../../../components/Pagination/Pagination';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../services/api';
import GetCreditCardOptions from '../../../components/C2Points/GetCreditCardOptions';
import { Controller, useForm } from 'react-hook-form';

export interface propInfo {
    info: any;
    loading: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
}

const TablePending: React.FC<propInfo> = ({
    info, loading, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage
}) => {
    const [reintegrateSite, setReintegrateSite]= useState<boolean>(false);
    const [reintegrateTotem, setReintegrateTotem]= useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('Loading');
    const [showModalInfos, setShowModalInfos]= useState<boolean>(false);

    const [information, setInformation] = useState<boolean>(false);
    const [messageInformation, setMessageInformation] = useState<any>(null);
    const [xmlInformation, setXmlInformation] = useState<any>(null);

    /* Dados para o reenvio */
    const [bookingLoc, setBookingLoc] = useState<any>(null);;
    const [date, setDate] = useState<any>(null);
    const [amount, setAmount] = useState<number>(0);
    const [selectedCard, setSelectedCard] = useState<string>('');
    const [installments, setInstallments] = useState<any>(1);
    const [paymentsOptions, setPaymentsOptions] = useState<any>('1');
    const [nsu, setNsu]= useState<any>('');
    const [autorizationCode, setautorizationCode]= useState<any>('');




    /* dados e funções da coluna  */

    function addDate(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex">
                    {cell.split("T")[0].split("-").reverse().join("/") + " " + cell.split("T")[1]}
                </div>
            </div>
        );
    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => handleInformation(row)}>
                                <FontAwesomeIcon
                                    icon={["fal", "file-alt"]}
                                    className="mr-2"
                                />
                                Informações
                            </Dropdown.Item>
                           {row?.source === 'site' || row?.source === 'sig' || row?.source === 'totem' || row?.source === 'bilheteria' ? <Dropdown.Item
                                eventKey="1"
                                onClick={() => reintegrateInformation(row)}
                                disabled= {false}>
                                <FontAwesomeIcon
                                    icon={["fas", "sync-alt"]}
                                    className="mr-2"
                                />
                                Reenvio
                            </Dropdown.Item>
                            : <></>
                           }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    const columns = [
        { dataField: "bookingLoc", text: "BookingLoc" },
        { dataField: "operacao", text: "Tipo de Operação" },
        { dataField: "source", text: "Canal de Venda" },
        { dataField: "date", text: "Data e Hora", formatter: addDate },
        { dataField: "", text: "Ações", formatter: addActionButton }
    ];

    const {
        control,
        formState: { errors },
      } = useForm();

    /*--------------------------------------------------*/

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "pending", text: "Pendências" }];

    const notFoundProducts = [{ pending: "Nenhuma pendência encontrada" }];
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "pending", text: "Carregando informações", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    const handleReintegrateSite = async () => {
        setModalLog(null);
        setReintegrateSite(false);
        setShowModalInfos(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/IntegrationTools/ReintegrateSite`,
                {
                    "bookingLoc": bookingLoc,
                    "paymentType": +paymentsOptions || 1,
                    "amount": +amount,
                    "date": date,
                    "installments": +installments || 1,
                    "brand": selectedCard
                }
                , config);
            if (res.status === 200) {
                if (res.data.data.log === 0 || res.data.data.log === '0') {
                    setModalLog(res.data.data.log);
                    setModalMessage('Dados Enviados Corretamente');
                } else if (res.data.data.log === 1 || res.data.data.log === '1') {
                    setModalLog(res.data.data.log);
                    setModalMessage('Dados Enviados Corretamente')
                } else {
                    setModalLog(1);
                    setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
                }
            } else {
                setModalLog(1);
                setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
        }
    }

    const handleReintegrateTotem = async () => {
        setModalLog(null);
        setReintegrateTotem(false);
        setShowModalInfos(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/IntegrationTools/ReintegrateTotem`,
                {
                    "bookingLoc": bookingLoc,
                    "paymentType": +paymentsOptions,
                    "nsu": nsu,
                    "codigoAutorizacao": autorizationCode,
                    "date": date,
                    "installments": +installments || 1,
                    "brand": selectedCard
                }
                , config);
            if (res.status === 200) {
                if (res.data.data.log === 0 || res.data.data.log === '0') {
                    setModalLog(res.data.data.log);
                    setModalMessage('Dados Enviados Corretamente');
                } else if (res.data.data.log === 1 || res.data.data.log === '1') {
                    setModalLog(res.data.data.log);
                    setModalMessage('Dados Enviados Corretamente');
                } else {
                    setModalLog(1);
                    setModalMessage(res.data.errorMessage);
                }
            } else {
                setModalLog(1);
                setModalMessage(res.data.errorMessage);
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
        }
    }


    const handleInformation = async (row: any) => {
        setInformation(true)
        
        setMessageInformation(row?.errorMessage);
        setXmlInformation(row?.xml);
    }

    const reintegrateInformation = async (row: any) => {
        if (row?.source === 'site' || row?.source === 'sig') {
            setReintegrateSite(true)
        } else if (row?.source === 'totem' || row?.source === 'bilheteria') {
            setReintegrateTotem(true);
        }

        setBookingLoc(row?.bookingLoc)
        setDate(row?.date)
    }

    const installmentsOptions = [
        { value: '1', label: '1x' },
        { value: '2', label: '2x' },
        { value: '3', label: '3x' },
        { value: '4', label: '4x' },
        { value: '5', label: '5x' },
        { value: '6', label: '6x' },
        { value: '7', label: '7x' },
        { value: '8', label: '8x' },
        { value: '9', label: '9x' },
        { value: '10', label: '10x' },
        { value: '11', label: '11x' },
        { value: '12', label: '12x' },
    ]

    const paymentOptions = [
        { value: '1', label: 'Cartão de Crédito'},
        { value: '96', label: 'Pix'},
        { value: '2', label: 'Dinheiro' },
        { value: '111', label: 'Pagamento externo' },
    ];

    if (info !== null) {
        return (
          <>
            <div className="table-default pending-rm-table">
              <div className="table-container">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={info}
                  columns={columns}
                  striped={true}
                  rowClasses={rowClasses}
                />

                <Pagination
                  totalRows={totalRows}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  selectNumberRows="yes"
                />
              </div>
            </div>

            <Modal
              size="lg"
              show={reintegrateSite !== false}
              onHide={() => setReintegrateSite(false)}
              aria-labelledby="contained-modal-title-vcenter"
              className="modal-confirm loading-modal"
            >
              <div className='p-4'>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4">Informações para Reenvio:</h4>
                  <FontAwesomeIcon
                    icon={['fal', 'times']}
                    className="text-info"
                    size="lg"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setReintegrateSite(false);
                    }}
                  />
                </div>
                <div>
                <div className="mb-3">
                            <label htmlFor="bookingLoc">BookingLoc:</label>
                            <input
                                type="text"
                                id="bookingLoc"
                                value={bookingLoc}
                                onChange={(e) => setBookingLoc(e.target.value)}
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="date">Data e Hora:</label>
                            <input
                                type="text"
                                id="date"
                                value={date?.split("T")[0].split("-").reverse().join("/") + " " + date?.split("T")[1]}
                                onChange={(e) => setDate(e.target.value)}
                                className="form-control"
                                disabled
                            />
                        </div>
                    <div>
                        <label htmlFor="amount">Valor:</label>
                         <input
                                required
                                type="number"
                                id="amount"
                                value={amount}
                                onChange={(e) => setAmount(+e.target.value)}
                                className="form-control"
                                min="0"
                                pattern="[0-9]*"
                                inputMode="numeric"
                            />
                        </div>
                        <div className="mt-3">
                            <label htmlFor="payments">Tipo de Pagamento:</label>
                            <select
                                id="payments"
                                value={paymentsOptions}
                                onChange={(e) => setPaymentsOptions(e.target.value)}
                                className="form-control"
                            >
                                {paymentOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        {paymentsOptions === '1' ? <div className="mt-3">
                            <label htmlFor="card">Cartão:</label>
                            <Controller
                            control={control}
                            name={`card`}
                            render={({ field }: any) => (
                                <GetCreditCardOptions propsField={field} propsErrors={errors} setCardState={setSelectedCard}/>
                              )}
                            />
                        </div> 
                        : 
                        <></>
                        }
                        {paymentsOptions === '1' ? <div className="mt-3">
                            <label htmlFor="installments">Parcelas:</label>
                            <select
                                id="installments"
                                value={installments}
                                onChange={(e) => setInstallments(e.target.value)}
                                className="form-control"
                            >
                                {installmentsOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div> : <></> }
                        <div>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary" onClick={handleReintegrateSite}>
                                Enviar
                            </button>
                        </div>
                </div>
              </div>
            </Modal>

            <Modal
              size="lg"
              show={reintegrateTotem !== false}
              onHide={() => setReintegrateTotem(false)}
              aria-labelledby="contained-modal-title-vcenter"
              className="modal-pending loading-modal"
            >
              <div className='p-4'>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4">Informações para Reenvio:</h4>
                  <FontAwesomeIcon
                    icon={['fal', 'times']}
                    className="text-info"
                    size="lg"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setReintegrateTotem(false);
                    }}
                  />
                </div>
                <div>
                <div className="mb-3">
                            <label htmlFor="bookingLoc">BookingLoc:</label>
                            <input
                                type="text"
                                id="bookingLoc"
                                value={bookingLoc}
                                onChange={(e) => setBookingLoc(e.target.value)}
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="date">Data e Hora:</label>
                            <input
                                type="text"
                                id="date"
                                value={date?.split("T")[0].split("-").reverse().join("/") + " " + date?.split("T")[1]}
                                onChange={(e) => setDate(e.target.value)}
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div>
                        <label htmlFor="amount">Nsu:</label>
                         <input
                                required
                                type="text"
                                id="amount"
                                value={nsu}
                                onChange={(e) => setNsu(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mt-3">
                        <label htmlFor="amount">Código de Autorização:</label>
                         <input
                                required
                                type="text"
                                id="amount"
                                value={autorizationCode}
                                onChange={(e) => setautorizationCode(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mt-3">
                            <label htmlFor="payments">Tipo de Pagamento:</label>
                            <select
                                id="payments"
                                value={paymentsOptions}
                                onChange={(e) => setPaymentsOptions(e.target.value)}
                                className="form-control"
                                defaultValue={"1"}
                            >
                                {paymentOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        {paymentsOptions === '1' ? <div className="mt-3">
                            <label htmlFor="card">Cartão:</label>
                            <Controller
                            control={control}
                            name={`card`}
                            render={({ field }: any) => (
                                <GetCreditCardOptions propsField={field} propsErrors={errors} setCardState={setSelectedCard}/>
                              )}
                            />
                        </div> 
                        : 
                        <></>
                        }
                        {paymentsOptions === '1' ? <div className="mt-3">
                            <label htmlFor="installments">Parcelas:</label>
                            <select
                                id="installments"
                                value={installments}
                                onChange={(e) => setInstallments(e.target.value)}
                                className="form-control"
                            >
                                {installmentsOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div> : <></> }
                        <div>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary" onClick={handleReintegrateTotem}>
                                Enviar
                            </button>
                        </div>
                </div>
              </div>
            </Modal>

            <Modal
              className="modal-custom reservation-details"
              show={information !== false}
              onHide={() => {
                setInformation(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div
                className="p-4"
                style={{ wordWrap: 'break-word', overflow: 'auto' }}
              >
                <div className="py-2">
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-4">XML:</h4>
                    <FontAwesomeIcon
                      icon={['fal', 'times']}
                      className="text-info"
                      size="lg"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setInformation(false);
                      }}
                    />
                  </div>
                  <div>{xmlInformation}</div>
                </div>
                <div className="pt-5 pb-2">
                  <h4 className="mb-4">Informações Recebidas</h4>
                  <div>{messageInformation}</div>
                </div>
              </div>
            </Modal>

            <Modal
              show={showModalInfos !== false}
              onHide={() => {
                  setShowModalInfos(false);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-confirm loading-modal"
            >
            <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalLog !== null
                                ?
                                <>
                                    <div>
                                        {
                                            modalLog === 1 || modalLog === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em" }}
                                                    className='text-primary'
                                                />
                                                :
                                                modalLog === 0 || modalLog === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                        }
                                    </div>
                                    <div>
                                        {modalMessage}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={()=> setShowModalInfos(false)}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div>
                                    <span>Processando</span>
                                </div>
                            </div>
                        }
                    </Modal.Body>

            </Modal>


            
          </>
        );
    } else if (loading === true) {
        return (
            <>
                <div className="table-default bills-receive-table loading not-found">
                    <div className="table-container">

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-receive-table not-found">
                    <div className="table-container">

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TablePending;