import React, { useState } from 'react';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from '../../../components/Pagination/Pagination';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../services/api';
import JsonToTable from './JsonToTable';

export interface propInfo {
    info: any;
    loading: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
}

const TableLogsReport: React.FC<propInfo> = ({
    info, loading, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage
}) => {
    const [reintegrate, setReintegrate] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('Loading');

    const [information, setInformation] = useState<boolean>(false);
    const [informationSent, setInformationSent] = useState<any>(null);
    const [informationReceived, setInformationReceived] = useState<any>(null);
    /* dados e funções da coluna  */

    function addDate(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex">
                    {cell.split("T")[0].split("-").reverse().join("/") + " " + cell.split("T")[1]}
                </div>
            </div>
        );
    }

    function addStatus(cell: any, row: any) {
        return (
            <div className='d-flex align-items-center flex-column' style={{ maxWidth: '100px' }}>
                <div className={
                    row.status === "Sucesso"
                        ?
                        "bg-ativo"
                        :
                        row.status === "Erro"
                            ?
                            "bg-inativo"
                            :
                            "bg-analise"
                }>
                    {row.status}
                </div>

            </div>
        );
    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => handleInformation(cell, row)}>
                                <FontAwesomeIcon
                                    icon={["fal", "file-alt"]}
                                    className="mr-2"
                                />
                                Informações
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => handleReintegrate(cell, row)}
                                disabled={row?.type !== "Finalize Checkout" || row?.status !== "Erro"}>
                                <FontAwesomeIcon
                                    icon={["fas", "sync-alt"]}
                                    className="mr-2"
                                />
                                Reenvio
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    const columns = [
        { dataField: "reference", text: "Reserva" },
        { dataField: "type", text: "Tipo de Integração" },
        { dataField: "date", text: "Data e Hora", formatter: addDate },
        { dataField: "description", text: "Descrição" },
        { dataField: "status", text: "Status", formatter: addStatus },
        { dataField: "", text: "Ações", formatter: addActionButton }
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "logs", text: "Logs" }];

    const notFoundProducts = [{ logs: "Nenhum log encontrado" }];
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "logs", text: "Carregando logs", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    const handleReintegrate = async (cell: any, row: any) => {
        setReintegrate(true);
        setModalLog(null);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/IntegrationTools/ReintegrateFinalizeCheckout`,
                {
                    "bookingLoc": row?.reference,
                }
                , config);
            if (res.status === 200) {
                if (res.data.data.log === 0 || res.data.data.log === '0') {
                    setModalLog(res.data.data.log);
                    setModalMessage(res.data.data.texto);
                } else if (res.data.data.log === 1 || res.data.data.log === '1') {
                    setModalLog(res.data.data.log);
                    setModalMessage(res.data.data.texto);
                } else {
                    setModalLog(1);
                    setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
                }
            } else {
                setModalLog(1);
                setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Por favor, entre em contato com o nosso suporte técnico!");
        }
    }


    const handleInformation = async (cell: any, row: any) => {
        setInformation(true)
        
        setInformationSent(row?.objectSent);
        setInformationReceived(row?.objectReceived);
    }

    if (info !== null) {
        return (
            <>
                <div className="table-default logs-report-table">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={info}
                            columns={columns}
                            striped={true}
                            rowClasses={rowClasses}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>

                <Modal
                    size="lg"
                    show={reintegrate}
                    onHide={() => setReintegrate(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modal-confirm loading-modal"
                >
                    <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalLog !== null
                                ?
                                <>
                                    <div>
                                        {
                                            modalLog === 1 || modalLog === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em" }}
                                                    className='text-primary'
                                                />
                                                :
                                                modalLog === 0 || modalLog === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                        className='text-primary'
                                                    />
                                        }
                                    </div>
                                    <div>
                                        {modalMessage}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={() => setReintegrate(false)}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div>
                                        <span>Carregando</span>
                                    </div>
                                    <div></div>
                                </div>
                        }
                    </Modal.Body>
                </Modal>

                <Modal
                    className="modal-custom reservation-details"
                    show={information !== false}
                    onHide={() => { setInformation(false) }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="p-4" style={{ wordWrap: 'break-word', overflow: 'auto' }}>
                        <div className='py-2'>
                            <div className='d-flex justify-content-between'>
                                <h4 className='mb-4'>Informações Enviadas</h4>
                                <FontAwesomeIcon
                                    icon={['fal', 'times']}
                                    className="text-info"
                                    size="lg"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { setInformation(false) }}
                                />
                            </div>
                            <div><JsonToTable data={informationSent} /></div>
                        </div>
                        <div className='pt-5 pb-2'>
                            <h4 className='mb-4'>Informações Recebidas</h4>
                            <div><JsonToTable data={informationReceived} /></div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    } else if (loading === true) {
        return (
            <>
                <div className="table-default bills-receive-table loading not-found">
                    <div className="table-container">

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-receive-table not-found">
                    <div className="table-container">

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableLogsReport;