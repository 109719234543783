import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SingleCalendarFilter from "../../../components/Calendar/SingleCalendarFilter";

export interface propFilter {
    buscar: any;
}

const FilterLogReport: React.FC<propFilter> = ({
    buscar
}) => {

    const { t } = useTranslation();

    const [filterBooking, setFilterBooking] = useState<string>("")
    const [filterDate, setFilterDate] = useState<any>([]);

    const onSubmit = () => {
        buscar(filterBooking, filterDate);
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleClearClick = () => {
        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterBooking("");
        setFilterDate("");
        buscar("", "", "", "");
    };

    const handleChangeBooking = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterBooking(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Listar Pendências</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>BookingLoc</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="booking"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            aria-invalid={errors?.user ? "true" : ""}
                                                            variant="standard"
                                                            margin="normal"
                                                            onChange={handleChangeBooking}
                                                            value={filterBooking}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <SingleCalendarFilter date={filterDate} setValue={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>

                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={() => { handleClearClick() }}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button type="submit" className="btn-default text-uppercase">{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterLogReport;