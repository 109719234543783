import React, { useEffect, useState, Key  } from 'react';

import Form from 'react-bootstrap/Form';

import api from '../../services/api';

/* import Form from 'react-bootstrap/Form'; */

export interface propPlus {
  propsField: any,
  propsErrors: any,
  setCardState: any
};


const GetCreditCardOptions: React.FC<propPlus> = ({
  propsField, propsErrors, setCardState
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetBrandCardSitef`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCardState(newValue);
  }

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.clientType !== undefined ? "endpoint-error" : "endpoint"}>
        <Form.Select
          {...propsField}
          as="select"
          variant="standard"
          margin="normal"
          onChange={(e: any) => handleChangeState(e)}
        >
          <option value='0' selected disabled>Selecione</option>
          {info.length > 0 ? info.map((info: any, index: Key) => {
            return (
              <option key={index} value={info.id}>
                {info.description}
              </option>
            )
          }) : ''}
        </Form.Select>
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetCreditCardOptions;