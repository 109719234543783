import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  handleChangeIntegrationType: any
};

const GetIntegrationType: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, handleChangeIntegrationType
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetIntegrationType`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <div className='endpoint'>
        <Form.Select
          {...propsField}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          onChange={(e: any) => handleChangeIntegrationType(e)}
        >
          <option value='0' selected>Todos</option>
          {info.length > 0
            ? info.map((elem: any, index: number) => {
              return (
                <option key={index} value={elem.id}>{elem.description}</option>
              );
            })
            :
            <></>
          }
        </Form.Select>
      </div>
    );
  } else {
    return <>

    </>
  }
}

export default GetIntegrationType;