import React from 'react';
import './JsonToTable.scss';


const JsonToTable = ({ data }: any) => {
  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
          <div key={index}>{renderValue(item)}</div>
        ));
      } else if (typeof value === 'object') {
        return <JsonToTable data={JSON.stringify(value)} />;
      }
    return value;
  };

  const renderTable = (data: any) => {
    if(data === "null" || data === undefined || data === null) {
      return <></>
    } else {
      return Object.entries(JSON.parse(data)).map(([key, value,], index) => {
        return (
          <tr key={index}>
            <td className='card-infos'>{key}</td>
            <td className='card-infos'>{renderValue(value)}</td>
          </tr>
        );
    });
    }
    
  };

  return (
    <table>
      <tbody>{renderTable(data)}</tbody>
    </table>
  );
};

export default JsonToTable;
