import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {

  function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  }

  value = date;

  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={openCalendar}
      value={value}
      readOnly
      id={'singleCalendarId'}
      placeholder={startTimeLocal ? convertDate(startTimeLocal) : "Quando?"}
      autoComplete='off'
    />
  )
}

function handleChange(e: any, setValue: any) {
  setValue([e.day + "/" + e.month + "/" + e.year]);
}

const SingleCalendarFilter = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false }: any) => {
  const { t } = useTranslation();

  const maxDate: any = new Date();

  return (
    <DatePicker
      {...field}
      //svalue={new Date(defaultDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      onChange={(e: any) => { handleChange(e, setValue) }}
      render={<CustomMultipleInput date={date} setValue={setValue} />}
    />
  )
}

export default SingleCalendarFilter;